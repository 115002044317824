<template>
  <div class="termCondition">
    <div class="title">
      {{ consents[activeConsentIndex].title }}
    </div>
    <div class="sub">
      ข้อกำหนดและเงื่อนไขในการบริการ Chang Family Line OA
      และประกาศว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลของ บริษัท ช้างอินเตอร์
      เนชั่นแนล
    </div>

    <div class="containBody">
      <div ref="wrapContent" class="wrapContent">
        <div
          class="content"
          v-html="consents[activeConsentIndex].content"
        ></div>
      </div>
      <div class="bottomSection">
        <div class="suggest">
          เพื่อป้องกันสิทธิของผู้ใช้บริการ
          โปรดอ่านเงื่อนไขการให้บริการทั้งหมดและตกลงที่จะปฏิบัติตามข้อตกลงและเงื่อนไขทั้งหมดต่อไปนี้
        </div>
        <div class="checkboxSection">
          <div
            v-if="
              !consents[activeConsentIndex].subConsent ||
              consents[activeConsentIndex].subConsent.length === 0
            "
          >
            <ConsentCheckboxItem
              :c="consents[activeConsentIndex]"
              :id="`consent-section-input-${consents[activeConsentIndex].id}`"
              v-model="consents[activeConsentIndex].isAccept"
              :required="consents[activeConsentIndex].isForce"
            />
          </div>
          <template v-for="sc in consents[activeConsentIndex].subConsent">
            <ConsentCheckboxItem
              :sc="sc"
              :c="consents[activeConsentIndex]"
              :id="`consent-section-input-${consents[activeConsentIndex].id}-${sc.subConsentId}`"
              v-model="sc.isAccept"
              :required="sc.isRequired"
              :key="`${consents[activeConsentIndex].id}-${sc.subConsentId}`"
            />
          </template>
        </div>
        <div
          :class="[{ disable: !currentPassed }, 'confirmBtn']"
          @click="onNext()"
        >
          ถัดไป
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConsentCheckboxItem from "@/components/ConsentCheckboxItem.vue";

export default {
  props: {
    consents: Array,
  },
  components: { ConsentCheckboxItem },
  data() {
    return {
      openModal: false,
      activeConsentIndex: 0,
    };
  },
  mounted() {},
  computed: {
    currentPassed() {
      const consent = this.consents[this.activeConsentIndex];
      if (consent?.subConsent?.length > 0) {
        return !consent.subConsent.some((s) => s.isRequired && !s.isAccept);
      }
      return consent.isForce && consent.isAccept;
    },
  },
  watch: {
    consentIndex: {
      handler() {
        this.$refs.wrapContent.scrollTo(0, 0);
      },
    },
  },
  methods: {
    close() {
      this.openModal = false;
      this.consents = [];
      this.activeConsentIndex = 0;
    },
    parseText(html) {
      return html.replace(/(<([^>]+)>)/gi, "");
    },
    async onNext() {
      if (this.activeConsentIndex >= this.consents.length - 1) {
        const submit_list = this.consents.map((c) => ({
          consentId: c.id,
          isAccept: c.subConsent && c.subConsent.length > 0 ? true : c.isAccept,
          ...(c.subConsent &&
            c.subConsent.length > 0 && {
              subConsentAccept: c.subConsent.map((s) => ({
                subConsentId: s.subConsentId,
                isAccept: s.isAccept || false,
              })),
            }),
        }));

        this.$emit("accepted", submit_list);
      } else {
        this.activeConsentIndex++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.termCondition {
  background: url("~@/assets/img/chang-register-bg.jpg"),
    linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  // background-blend-mode: overlay;
  background-size: cover;
  min-height: 100%;
  // display: flex;
  // justify-content: space-around;
  // flex-direction: column;
  text-align: center;
  font-size: 15px;
}

.termCondition {
  color: #2c2c2c;
}

.title,
.sub,
.confirmBtn {
  text-shadow: 1px 1px #000000;
}

.title {
  font-size: 20px;
  background: #001b0666;
  padding: 14px;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
}

.sub {
  color: white;
  font-size: 13px;
  line-height: 18px;
  margin: 10px;
}

.containBody {
  background: #e5e5e7;
  padding: 10px;
}

.wrapContent {
  height: 58vh;
  // padding: 10px;
  overflow: auto;
}

.content {
  overflow: auto;
  box-shadow: 0 0 3px #00000025;
  border-radius: 10px;
  margin: 8px;
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: unset;
}

.suggest {
  font-size: 12px;
  padding-top: 10px;
  line-height: 18px;
}

.confirmBtn {
  margin: 10px auto 0 auto;
  max-width: 400px;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  background: var(--primary-color);
  align-items: center;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  height: 40px;
  color: #ffffff;
  &.disable {
    pointer-events: none;
    background: #696969b0;
    box-shadow: none;
    color: #a3a3a3;
  }
}

.checkboxSection {
  margin: 0 auto;
  padding-left: 15px;
  label {
    margin-left: 5px;
    margin-right: 4px;
    text-align: left;
    line-height: 18px;
    font-size: 13px;
  }
  span {
    text-decoration: underline;
  }
}
</style>