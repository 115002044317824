<template>
  <div class="register">
    <div>
      <div class="title">
        อยากสั่ง แค่กด "ช้างชวนช้อป" <br />
        ง่ายๆ ลืมความวุ่นวายในการสั่งซื้อ
      </div>
      <div class="des">
        <br />
        "ช้างชวนช้อป" ช่องทางใหม่บน Line OA ให้ซับเอเย่นต์เลือกซื้อสินค้าออนไลน์
        จากเอเย่นต์ เอเย่นต์สามารถจัดการร้านค้า โปรโมชั่น
        และคำสั่งซื้อของซับเอเย่นต์ได้อย่างง่ายดาย บน Platform LINE OA Chang
        Family
        <div class="word"></div>
      </div>
    </div>
    <div class="bottomSection">
      <!-- <div class="checkboxSection">
        <div>
          <Checkbox v-model="termInput" :name="'termInput'" />
          <label for="termInput">ฉันยอมรับ </label
          ><span @click.stop="termModal = true"> ข้อตกลงและเงื่อนไข</span>
        </div>
        <div>
          <Checkbox v-model="privacyInput" :name="'privacyInput'" />
          <label for="privacyInput">ฉันยอมรับ</label
          ><span @click.stop="privacyModal = true"> นโยบายความเป็นส่วนตัว</span>
        </div>
      </div> -->
      <div :class="'nextBtn'" @click="$router.push('/chang/condition')">
        ต่อไป
      </div>
    </div>
    <!-- <Modal :open="termModal" @close="termModal = false">
      <TermCondition />
    </Modal> -->
    <!-- <Modal :open="privacyModal" @close="privacyModal = false">
      <Privacy />
    </Modal> -->
  </div>
</template>

<script>
// import Modal from "../../components/Modal";
// import Checkbox from "../../components/Checkbox";
// import TermCondition from "./TermCondition";
// import Privacy from "./Privacy";

export default {
  components: {},
  data() {
    return {
      // termModal: false,
      // privacyModal: false,
      // privacyInput: false,
      // termInput: false,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.register {
  background: url("~@/assets/img/chang-register-bg.jpg"),
    linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-blend-mode: overlay;
  background-size: cover;
  min-height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  color: #ffffff;
  font-size: 15px;
  line-height: 30px;
}

.title,
.des,
.nextBtn {
  text-shadow: 1px 1px #000000;
}

.word {
  display: inline-block;
}

.title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
  color: #ffffff;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.des {
  text-align: center;
  padding: 10px 20px;
  line-height: 25px;
}

.nextBtn {
  margin: 40px auto 0 auto;
  max-width: 400px;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  background: var(--primary-color);
  align-items: center;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  height: 40px;
  &.disable {
    pointer-events: none;
    background: #696969b0;
    box-shadow: none;
    color: #a3a3a3;
  }
}

.bottomSection {
  text-align: left;
  padding: 20px;
}
</style>