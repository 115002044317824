<template>
  <ConsentContent :consents="consentList" @accepted="onAccepted" />
</template>

<script>
import ConsentContent from "./components/ConsentContent";
import errorCode from "@/utils/errorCode";

export default {
  components: { ConsentContent },
  data() {
    return {
      consentList: [],
    };
  },
  mounted() {
    this.fetchConsent();
  },
  methods: {
    onAccepted(accepted_list) {
      this.registerSSO(accepted_list);
    },
    async fetchConsent() {
      window.loading(true);
      try {
        const res = await fetch(`${process.env.VUE_APP_API_URL}/api/consents`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.$authToken}`,
          },
        });

        const body = await res.json();

        if (res.status !== 200) {
          throw errorCode(108);
        }

        this.consentList = body;
      } catch (error) {
        console.error("!get consents error: ", error);
        this.$router.replace({
          name: "error",
          params: error,
          replace: true,
        });
      } finally {
        window.loading(false);
      }
    },
    async registerSSO(consent_list) {
      window.loading(true);
      try {
        const reqBody = {
          authChannel: "sso",
          authCredential: {
            key: window.$extKey,
            accessToken: window.$extToken,
          },
          consents: consent_list,
        };

        const res = await fetch(`${process.env.VUE_APP_API_URL}/api/users`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.$authToken}`,
          },
          body: JSON.stringify(reqBody),
        });

        if (res.status !== 201 && res.status !== 200) {
          throw errorCode(109);
        }

        this.$router.replace({
          path: "/products/thaibev",
        });
      } catch (error) {
        console.error("!post register error: ", error);
        this.$router.replace({
          name: "error",
          params: error,
          replace: true,
        });
      } finally {
        window.loading(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.termCondition {
  background: url("~@/assets/img/chang-register-bg.jpg"),
    linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  // background-blend-mode: overlay;
  background-size: cover;
  min-height: 100%;
  // display: flex;
  // justify-content: space-around;
  // flex-direction: column;
  text-align: center;
  font-size: 15px;
}

.termCondition {
  color: #2c2c2c;
}

.title,
.sub,
.confirmBtn {
  text-shadow: 1px 1px #000000;
}

.title {
  font-size: 20px;
  background: #001b0666;
  padding: 14px;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
}

.sub {
  color: white;
  font-size: 13px;
  line-height: 18px;
  margin: 10px;
}

.containBody {
  background: #e5e5e7;
  padding: 10px;
}

.wrapContent {
  height: 58vh;
  // padding: 10px;
  overflow: auto;
}

.content {
  overflow: auto;
  box-shadow: 0 0 3px #00000025;
  border-radius: 10px;
  margin: 8px;
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: unset;
}

.suggest {
  font-size: 12px;
  padding-top: 10px;
  line-height: 18px;
}

.confirmBtn {
  margin: 10px auto 0 auto;
  max-width: 400px;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  background: var(--primary-color);
  align-items: center;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  height: 40px;
  color: #ffffff;
  &.disable {
    pointer-events: none;
    background: #696969b0;
    box-shadow: none;
    color: #a3a3a3;
  }
}

.checkboxSection {
  margin: 0 auto;
  padding-left: 15px;
  > div {
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  label {
    margin-left: 5px;
    margin-right: 4px;
    text-align: left;
    line-height: 18px;
    font-size: 13px;
  }
  span {
    text-decoration: underline;
  }
}
</style>